import { Injectable } from '@angular/core';
import { NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root'
})
export class ModalsOptionsService {

  constructor(
  ) { }

  getDefaultModalOptionsSm(): NgbModalOptions {

    return {
      size: 'sm',
      modalDialogClass: '',
      windowClass: '',

    };
  }

  getDefaultModalOptionsMd(): NgbModalOptions {

    return {
      size: 'md',
      modalDialogClass: '',
      windowClass: '',

    };
  }

  getModalOptionsXl(): NgbModalOptions {

    return {
      size: 'xl',
      modalDialogClass: '',
      windowClass: '',

    };
  }
}
